import axios from 'axios';

function getBookingRequestListing(payload, wl_code) {
  const wlCode = axios.defaults.headers.wl_code
  wl_code = wl_code !== 'all' ? wl_code : 1
  if (wl_code) {
    axios.defaults.headers.wl_code = wl_code
  }
  console.log('payload ',payload);
  const response = axios.get("on-request-offer/list", { params: payload });
  axios.defaults.headers.wl_code = wlCode
  return response
}

function changeBookingStatus(data, wl_code) {
	let wlCode = axios.defaults.headers.wl_code
	wl_code = wl_code !== 'all' ? wl_code : 1
    if(wl_code){
        axios.defaults.headers.wl_code = wl_code
    }
	const response = axios.patch(`on-request-offer/${data.booking_quote_id}/${data.statusType}`);
	axios.defaults.headers.wl_code = wlCode
	return response
}

function deleteRequest(id,wl_code) {
  return axios.delete(`on-request-offer/${id}`)
}

function addEditComment(id,data,wl_code){
  let wlCode = axios.defaults.headers.wl_code
  if(wl_code){
      axios.defaults.headers.wl_code = wl_code
  }
  const response = axios.post(`on-request-offer/comment/${id}`,data)
  axios.defaults.headers.wl_code = wlCode
  return response

}
function deleteComment(id){
  return axios.delete(`on-request-offer/comment/${id}`)
}
export default {
  getBookingRequestListing,
  deleteRequest,
  changeBookingStatus,
  addEditComment,
  deleteComment
}
