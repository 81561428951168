<template>
  <div>
    <confirm-delete
      ref="modal"
      :msg="deleteMsg"
      :title="titleMsg"
      @on-submit="deleteOnRequestOffer"
    />
    <confirm-delete
      ref="sendEmailModal"
      :msg="sendEmailMsg"
      :title="titleMsg"
      @on-submit="sendCustomerBookingEmail"
    />
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Filters</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <!-- Search -->

          <b-col cols="12" :md="wl_code == 1 ? '4' : '4'">
            <div>
              <b-form-group label="Search Keyword" label-for="mc-select-date">
                <validation-provider
                  #default="{ errors }"
                  name="refund amount"
                  rules="noSpecial"
                >
                  <b-form-input
                    v-model="search"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="12" :md="wl_code == 1 ? '4' : '4'">
            <div>
              <b-form-group label="Offer ID" label-for="mc-select-date">
                <validation-provider
                  #default="{ errors }"
                  name="refund amount"
                  rules="noSpecial"
                >
                  <b-form-input
                    v-model="search_id"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="12" md="4" class="mb-md-1 mb-2">
            <label>Booking Status</label>
            <v-select
              v-model="status"
              :options="BookingRequestStatusEnum"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="w-100"
              :reduce="(val) => val.value"
              :clearable="true"
            />
          </b-col>
        </b-row>
        <b-row>

          <b-col cols="12" md="4">
            <div>
              <b-form-group label="Email" label-for="mc-select-date">
                <b-form-input
                  v-model="email"
                  class="d-inline-block mr-1"
                  placeholder="Email"
                />
              </b-form-group>
            </div>
          </b-col>

        </b-row>
        <b-row>
          <b-col cols="12" md="4" class="demo-inline-spacing">
            <b-button
              variant="primary"
              class="float-right"
              @click="resetFilter"
            >
              Reset
            </b-button>
            <b-button
              class="float-right"
              variant="primary"
              type="Apply"
              @click="applyFilter"
            >
              Apply
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search by offer name or id or slug"
              /> -->
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        v-if="!contentLoading"
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- <template #empty="scope">
            <div class="my-2">
              {{ scope.emptyText }}
            </div>
          </template> -->

        <template #cell(message)="data">
          <div>
            <!--eslint-disable-next-line vue/no-v-html -->
            <span
              v-if="!data.item.isRead"
              v-html="data.item.message.slice(0, 200)"
            />
            <span
              v-if="
                !data.item.isRead &&
                  data.item.message.length >= 5
              "
              href="#"
              @click="activateReadMore(data.item.id)"
            >
              Read more...
            </span>
            <!--eslint-disable-next-line vue/no-v-html -->
            <span
              v-if="data.item.isRead"
              v-html="data.item.message"
            />
            <a
              v-if="
                data.item.isRead &&
                  data.item.message.length >= 180
              "
              class=""
              href="#"
              @click="activateReadLess(data.item.id)"
            >
              Read less...
            </a>
          </div>
        </template>
        <template #cell(createdDate)="data">
          <b-badge pill class="text-capitalize">
            <span v-if="data.item.createdDate">
              {{ data.item.createdDate | formatDate }}
            </span>
            <span v-else> - </span>
          </b-badge>
        </template>
        <template #cell(updatedDate)="data">
          <b-badge pill class="text-capitalize">
            <span v-if="data.item.createdDate">
              {{ data.item.updatedDate | formatDate }}
            </span>
            <span v-else> - </span>
          </b-badge>
        </template>
        <template #cell(phoneNo)="data">
          <span v-if="data.item.phoneNo">
            {{ data.item.phoneNo }}
          </span>
          <span v-else> NA </span>
        </template>
        <template #cell(status)="data">
          <b-badge
            v-if="
              checkLoginRole() === RoleEnum.SuperAdmin && wl_code == 1
                ? true
                : checkAccess.Edit && wl_code == 1
            "
            style="cursor: pointer"
            pill
            :variant="`light-${resolveMainBookingRequestStatusVariant(
              data.item.status ? data.item.status : ''
            )}`"
            @click="
              openStatusModal(
                data.item.status ? data.item.status : '',
                data.item.id,
                data.item.cancellationReason,
                data.item.status &&
                  data.item.status === 'Confirmed'
                  ? true
                  : false
              )
            "
          >
            {{ data.item.status ? data.item.status : "" }}
          </b-badge>
          <b-badge
            v-else
            pill
            :variant="`light-${resolveMainBookingRequestStatusVariant(
              data.item.status ? data.item.status : ''
            )}`"
          >
            {{ data.item.status ? data.item.status : "" }}
          </b-badge>
        </template>
        <template #cell(comment)="data">
          <span v-if="data.item.comment">
            {{ data.item.comment }}
            <feather-icon icon="EditIcon" @click="openCommentModal(
              data.item.id,
              data.item.comment
            )"
            />
          </span>
          <span v-else @click="openCommentModal(
            data.item.id,``
          )"
          > +  </span>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.delete
              "
              @click="openModal(data.item.id, data.item.name)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>
      <Loader v-if="contentLoading" />
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing  of {{ totalUsers }} entries</span> -->
            <span class="text-muted">Showing
              {{ totalBookings >= 1 ? startIndex + 1 : startIndex }}
              to
              {{ endIndex > totalBookings ? totalBookings : endIndex }}
              of {{ totalBookings }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalBookings > 0 && perPage"
              v-model="currentPage"
              :total-rows="totalBookings"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <b-modal
        id="modal-send-email"
        title="Send Email"
        cancel-title="Close"
        ok-title="Send"
        size="md"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        @ok="sendCustomerBookingEmail"
      >
        <b-overlay
          variant="white"
          :show="!isLoaded"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <validation-observer ref="emailFormRules">
            <b-form
              ref="emailForm"
              :style="{height: trHeight}"
              class="repeater-form"
              @submit.prevent
            >
              <b-row>
                <b-col>
                  <h4 for="emails">
                    Message:
                  </h4>
                </b-col>
                <b-col cols="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Message"
                    rules="max:250|min:3"
                  >
                    <b-form-group>
                      <quill-editor
                        id="message"
                        v-model="sendEmailForm.message"
                        class="message"
                        :options="snowOption"
                        :preserve-whitespace="false"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Message"
                      />
                    <!-- <b-form-textarea
                      id="textarea-default"
                      v-model="sendEmailForm.message"
                      placeholder="Message"
                      rows="3"
                    /> -->
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="11">
                  <div class="helper" />
                  <label class="btn btn-primary">
                    Attachment
                    <input
                      type="file"
                      accept="*"
                      name="doc"
                      @change="onChange"
                    >
                  </label>
                  {{ filePreview }}
                </b-col>
                <!-- </b-row>
            <b-row> -->
                <b-col cols="1" class="d-flex align-items-right justify-content-right mb-2 mb-md-1">
                  <b-row v-if="filePreview">
                    <button @click="removeFile">
                      X
                    </button>
                  </b-row>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-overlay>
      </b-modal>
    </b-card>
    <booking-request-form-modal
      ref="BookingRequestFormModal"
      :is-loaded="isLoaded"
      :status-details="statusDetails"
      @on-submit="changeBookingRequestStatus"
    />
    <comment-add-edit-modal
      ref="CommentAddEditModal"
      :comment-details="commentDetails"
      @on-submit="addCommentvalue"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardHeader,
  BCardBody,
  BFormInput,
  BFormGroup,
  BTable,
  BPagination,
  BBadge,
  VBTooltip,
  BForm,
  BButton,
  BMedia,
  BAvatar,
  BModal,
  BProgress,
  BLink,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import { avatarText } from "@core/utils/filter";
import "vue2-datepicker/index.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import { ValidationProvider } from "vee-validate";
import {
  perPageOptions,
  perPage,
  currentPage,
  resConditionCheck,
  BookingStatus,
  resolveBookingStatusVariant,
  AgencyStatus,
  TripType,
  PaymentType,
  WhiteLabel,
  BookingRequestStatusEnum,
  PaymentTypeEnum,
  AgencyEnum,
  tripTypeFilter,
  BookingModeEnum,
  resolveMainBookingRequestStatusVariant,
  extrasInitialVariant,
  RoleEnum,
  deleteConfirmMsg,
} from "@/helpers/constant";
import bookingRequestService from "@/services/booking-request/bookingRequest.service";
import whitelabelService from "@/services/whitelabel/whitelabel.service";
import errorResponseHandler from "@/services/errorHandler";
// import refundModal from '@/views/modal/BookingRequestFormModal.vue'
import {
  required,
  integer,
  noSpecial,
} from "@/@core/utils/validations/validations";
import BookingRequestFormModal from "@/views/modal/BookingRequestFormModal.vue";
import Loader from "@/layouts/skeloton-loader/Loader.vue";
import axios from "axios";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import CommentAddEditModal from "@/views/modal/CommentAddEditModal.vue"
import ConfirmDelete from "../../confirm-delete/ConfirmDelete.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    vSelect,
    BCardHeader,
    BCardBody,
    BFormInput,
    DatePicker,
    BFormGroup,
    BTable,
    BPagination,
    BBadge,
    BForm,
    ValidationProvider,
    BookingRequestFormModal,
    BButton,
    Loader,
    BMedia,
    BAvatar,
    BModal,
    BProgress,
    BLink,
    BDropdown,
    BDropdownItem,
    ConfirmDelete,
    CommentAddEditModal,
  },

  directives: {
    "b-tooltip": VBTooltip,
  },

  data() {
    return {
      contentLoading: true,
      exportContentLoading: false,
      perPageOptions,
      perPage,
      currentPage,
      items: [],
      startIndex: 0,
      endIndex: 0,
      totalBookings: 0,
      isSortDirDesc: true,
      sortBy: "",
      BookingStatus,
      resolveBookingStatusVariant,
      resolveMainBookingRequestStatusVariant,
      extrasInitialVariant,
      AgencyStatus,
      TripType,
      PaymentType,
      WhiteLabel,
      avatarText,
      BookingModeEnum,
      tripTypeFilter,
      AgencyEnum,
      PaymentTypeEnum,
      BookingRequestStatusEnum,
      required,
      integer,
      tableColumns: [
        { key: "id" },
        { key: "offerId" },
        { key: "createdDate" },
        { key: "name" },
        { key: "status" },
        { key: "email" },
        { key: "phoneNo" },
        { key: "updatedDate" },
        { key: "message" },
        { key: "comment" },
        { key: "actions" },
      ],
      showStatus: "",
      status: "",
      search_id: "",
      search: "",
      payment_via: "",
      customer_type: "",
      module_id: "",
      booking_id: "",
      email: "",
      bookingRange: "",
      booking_start_date: null,
      booking_end_date: null,
      travelRange: "",
      travel_start_date: "",
      travel_end_date: "",
      statusDetails: {},
      commentDetails: {},
      booking_mode: "",
      isLoaded: false,
      extrasAmount: "",
      pendingAmount: "",
      noSpecial,
      exportResults: [],
      extrasInitials: [],
      wl_code: axios.defaults.headers.wl_code,
      whitelabelList: [],
      wlCode: "",
      RoleEnum,
      checkLoginRole,
      checkAccess: {},
      module_supplier_id: "",
      progressExport: 10,
      tripTypeFilter2: tripTypeFilter,
      titleMsg: "",
      deleteMsg: "",
      id: "",
      sendEmailMsg:
                "Are you sure you want to send booking email to customer ?",
      titleMsg: "Send Email Confirmation",
      sendEmailForm: {
        emails: [{ id: 1, email: '' }],
        message: '',
        file: ''
      },
      nextEmailId: 1,
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline"]
          ]
        }
      },
      isError: false,
      filePreview: '',
      agentName: ''
    };
  },

  computed: {
    exportDisabled() {
      if (
        (this.bookingRange.length && this.bookingRange[0])
          || (this.travelRange.length && this.travelRange[0])
      ) {
        return !(this.totalBookings > 0);
      }
      return true;
    },
  },

  watch: {
    statusDetails: {
      handler(val) {
        console.log('statusDetails ', val);
      }
    },
    search_id: {
      handler(val) {
        console.log('search_id ', val);
      }
    },
    status: {
      handler(val) {
        console.log('status ', val)
        if (val == null) {
          this.showStatus = ""
        } else if (val == 0) {
          this.showStatus = "Pending"
        } else if (val == 1) {
          this.showStatus = "Ongoing"
        } else if (val == 2) {
          this.showStatus = "Completed"
        }
      }
    },
    perPage: {
      handler(val) {
        // this.currentPage = 1;
        // this.getExportData();
        const searchData = JSON.parse(sessionStorage.getItem("searchData"));
        if (searchData) {
          searchData.no_of_result = val; // updating the status property
          this.currentPage = searchData.page;
          this.wlCode = searchData.wlCode;
          sessionStorage.setItem("searchData", JSON.stringify(searchData)); // updating the searchData in sessionStorage
        }
        this.getBookingListing();
      },
    },
    currentPage: {
      handler(val) {
        // this.getExportData();
        const searchData = JSON.parse(sessionStorage.getItem("searchData"));
        if (searchData) {
          searchData.page = val; // updating the status property
          this.perPage = searchData.no_of_result;
          this.wlCode = searchData.wlCode;
          sessionStorage.setItem("searchData", JSON.stringify(searchData)); // updating the searchData in sessionStorage
        }
        this.getBookingListing();
      },
    },

    // status: {
    //   handler() {
    //     this.currentPage = 1;
    //     // this.getBookingListing();
    //     // this.getExportData();
    //   },
    // },
    // payment_via: {
    //   handler() {
    //     this.currentPage = 1;
    //     // this.getBookingListing();
    //     // this.getExportData();
    //   },
    // },
    // customer_type: {
    //   handler() {
    //     this.currentPage = 1;
    //     // this.getBookingListing();
    //     // this.getExportData();
    //   },
    // },
    // module_id: {
    //   handler() {
    //     this.currentPage = 1;
    //     // this.getBookingListing();
    //     // this.getExportData();
    //   },
    // },
    // booking_id: {
    //   handler() {
    //     this.currentPage = 1;
    //     // this.getBookingListing();
    //     // this.getExportData();
    //   },
    // },
    // email: {
    //   handler() {
    //     this.currentPage = 1;
    //     // this.getBookingListing();
    //     // this.getExportData();
    //   },
    // },
    bookingRange: {
      handler(newValue) {
        this.booking_start_date = newValue[0] && newValue[0] !== null
          ? moment(newValue[0]).format("YYYY-MM-DD")
          : "";
        this.booking_end_date = newValue[1] && newValue[1] !== null
          ? moment(newValue[1]).format("YYYY-MM-DD")
          : "";
        const searchData = JSON.parse(sessionStorage.getItem("searchData"));
        console.log('searchD', searchData)
        if (searchData) {
          searchData.bookingRange = !newValue.includes(null) ? newValue : "";
          searchData.booking_start_date = this.booking_start_date;
          searchData.booking_end_date = this.booking_end_date;
          sessionStorage.setItem("searchData", JSON.stringify(searchData));
          console.log('asdasdas', sessionStorage.getItem())
        }
        // this.getBookingListing();
        // this.getExportData();
      },
    },
    travelRange: {
      handler(newValue) {
        this.travel_start_date = newValue[0] && newValue[0] !== null
          ? moment(newValue[0]).format("YYYY-MM-DD")
          : "";
        this.travel_end_date = newValue[1] && newValue[1] !== null
          ? moment(newValue[1]).format("YYYY-MM-DD")
          : "";
        const searchData = JSON.parse(sessionStorage.getItem("searchData"));
        if (searchData) {
          searchData.travelRange = !newValue.includes(null) ? newValue : "";
          searchData.travel_start_date = this.travel_start_date;
          searchData.travel_end_date = this.travel_end_date;
          sessionStorage.setItem("searchData", JSON.stringify(searchData));
        }
        // this.getBookingListing();
        // this.getExportData();
      },
    },
    // booking_mode: {
    //   handler() {
    //     this.currentPage = 1;
    //     // this.getBookingListing();
    //     // this.getExportData();
    //   },
    // },
    item: {
      async handler(val) {
        console.log('asdas', val[0]);
      },
    },
    wlCode: {
      async handler(val) {
        // this.wlCode = val ? this.wlCode : "all";
        const searchData = JSON.parse(sessionStorage.getItem("searchData"));
        if (searchData) {
          searchData.wlCode = val;
          this.currentPage = searchData.page;
          this.perPage = searchData.no_of_result;
          sessionStorage.setItem("searchData", JSON.stringify(searchData)); // updating the searchData in sessionStorage
        }
        // this.getBookingListing();
      },
    },
    // module_supplier_id: {
    //   handler() {
    //     this.currentPage = 1;
    //     // this.getBookingListing();
    //     // this.getExportData();
    //   },
    // },
  },

  async mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    const searchData = JSON.parse(sessionStorage.getItem("searchData"));
    if (searchData) {
      this.perPage = searchData.no_of_result;
      this.currentPage = searchData.page;
      this.isSortDirDesc = searchData.order === "DESC";
      this.sortBy = searchData.order_by;
      this.status = searchData.status;
      this.payment_via = searchData.payment_via;
      this.customer_type = searchData.customer_type;
      this.module_id = searchData.module_id;
      this.booking_id = searchData.booking_id;
      this.email = searchData.email;
      this.bookingRange = searchData.bookingRange && !searchData.bookingRange.includes(null)
        ? [
          new Date(searchData.bookingRange[0]),
          new Date(searchData.bookingRange[1]),
        ]
        : "";
      this.travelRange = searchData.travelRange && !searchData.travelRange.includes(null)
        ? [
          new Date(searchData.travelRange[0]),
          new Date(searchData.travelRange[1]),
        ]
        : "";
      this.booking_start_date = searchData.booking_start_date;
      this.booking_end_date = searchData.booking_end_date;
      this.travel_start_date = searchData.travel_start_date;
      this.travel_end_date = searchData.travel_end_date;
      this.booking_mode = searchData.booking_mode;
      this.module_supplier_id = searchData.module_supplier_id;
    }
    if (this.$route.query) {
      this.currentPage = this.$route.query.page ? this.$route.query.page : 1;
      this.perPage = this.$route.query.rows ? this.$route.query.rows : 10;
      this.wlCode = this.$route.query.wl_code
        ? this.$route.query.wl_code
        : "all";
    }
    await this.getBookingListing();
    if (this.wl_code == 1) {
      await this.getWhitelabelList();
    }
    await this.getWhitelabelDetails();
    // this.getExportData();
  },

  methods: {

    activateReadMore(id) {
      const item = this.items.find(e => e.id === id);
      item.isRead = true;
    },

    activateReadLess(id) {
      const item = this.items.find(e => e.id === id);
      item.isRead = false;
    },
    openModal(userId, userName) {
      this.titleMsg = deleteConfirmMsg;
      this.deleteMsg = `Are you sure you want to delete ${userName}'s' request offer ?`;
      this.id = userId;
      this.$refs.modal.show();
    },
    deleteOnRequestOffer(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.modal.loading = true;
      if (this.id) {
        bookingRequestService.deleteRequest(this.id)
          .then(res => {
            if (resConditionCheck(res.status)) {
              this.$refs.modal.loading = false;
              this.$refs.modal.toggleModal = false;
              this.items = this.items.filter(
                item => item.id !== this.id
              );
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.modal.loading = false;
            this.$refs.modal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    },
    onChange(e) {
      const { files } = e.target;
      this.filePreview = files[0]?.name;
      this.sendEmailForm.file = files[0];
    },
    removeFile() {
      this.sendEmailForm.file = "";
      this.filePreview = "";
    },
    resetSendEmailForm() {
      setTimeout(() => {
        this.sendEmailForm = { emails: [{ id: 1, email: this.contactDetails.email }], message: '', file: '' };
        this.nextEmailId = 1;
      }, 500)
    },
    async sendCustomerBookingEmail(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.emailFormRules.validate().then(async success => {
        if (success) {
          try {
            this.isLoaded = false;

            const formdata = new FormData();
            formdata.append("send_email", true)
            formdata.append("emails", (this.sendEmailForm.emails && this.sendEmailForm.emails.length) ? this.sendEmailForm.emails.map(e => e.email) : [])
            formdata.append("message", this.sendEmailForm.message)
            formdata.append("file", this.sendEmailForm.file)

            const res = await bookingRequestService.sendBookingEmail(this.$route.params.id, formdata, this.$route.query?.wl_code);
            if (resConditionCheck(res.status) && res.data) {
              this.isLoaded = true;
              this.resetSendEmailForm();
              this.$nextTick(() => {
                this.$emit("on-submit");
                this.$bvModal.hide("modal-send-email");
              });
              this.filePreview = "";
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Booking email sent successfully.',
                  icon: "X-CircleIcon",
                  variant: "success"
                }
              });
            }
          } catch (error) {
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          }
        }
      })
    },
    repeateAgain() {
      this.sendEmailForm.emails.push({
        id: this.nextEmailId += 1,
        email: ""
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.emailRow[0].offsetHeight)
      })
    },
    async getBookingListing() {
      const WLCODE = sessionStorage.getItem("signinWLAdmin")
        && sessionStorage.getItem("whitelabel_code")
        ? sessionStorage.getItem("whitelabel_code")
        : this.wl_code !== 1
          ? this.wl_code
          : this.wlCode;
      this.contentLoading = true;
      const payload = {
        no_of_result: this.perPage,
        page: this.currentPage,
        status: this.showStatus,
        all_whitelabel: this.wlCode === "all",
        email: this.email,
        search: this.search,
        offer_id: this.search_id,
        order_by: "createdDate",
        order: "DESC"
      };

      try {
        const res = await bookingRequestService.getBookingRequestListing(payload, WLCODE);
        if (resConditionCheck(res.status) && res.data.data) {
          // console.log("booking response", res.data.data);
          this.contentLoading = false;
          this.items = res.data.data.map(e => {
            const obj = {
              ...e,
              isRead: false,
            };
            return obj;
          });
          // this.items.forEach((item, index) => {
          //   let adult = 0;
          //   let child = 0;
          //   let infant = 0;
          //   // eslint-disable-next-line no-unused-expressions
          //   item?.bookingTravellers.forEach((cItem) => {
          //     adult =
          //       cItem.traveller.passanger_code === "ADT" ? adult + 1 : adult;
          //     child =
          //       cItem.traveller.passanger_code === "CHD" ? child + 1 : child;
          //     infant =
          //       cItem.traveller.passanger_code === "INF" ? infant + 1 : infant;
          //   });
          //   const travellerAgeCount = { adult, child, infant };
          //   this.items[index].travellerAgeCount = travellerAgeCount;
          // });
          console.log('items ', this.items)
          this.totalBookings = res.data.count;
          this.startIndex = this.currentPage * this.perPage - this.perPage;
          this.endIndex = this.startIndex + this.perPage;
        }
        payload.wlCode = this.wlCode ? this.wlCode : "all";
        sessionStorage.setItem("searchData", JSON.stringify(payload));
      } catch (error) {
        console.log(error);
        this.contentLoading = false;
        const errorData = errorResponseHandler(error);
        if (error?.response?.status === 404) {
          this.items = [];
          this.totalBookings = 0;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        }
      }
    },

    getPendingAmount(data) {
      const pending = data.filter(e => e.paymentStatus !== 1);
      const pendingAmount = pending.reduce((curr, z) => {
        let current = curr;
        current += Number(z.amount);
        return current;
      }, 0);
      return pendingAmount;
    },

    calculateRetailPrice(moduleInfo, extras, flightModuleInfo) {
      const moduleInfo1 = moduleInfo;
      if (extras?.length) {
        extras.forEach(e => {
          if (e.type === "Promocode") {
            moduleInfo1.discount = e.amount;
            moduleInfo1.code = e.code;
          }
        });
      }
      const retailPrice = flightModuleInfo
        ? Number(flightModuleInfo.moduleInfo.flight_price)
            + Number(moduleInfo1.sellingPrice)
        : Number(moduleInfo1.sellingPrice);
      return retailPrice;
    },

    calculateTotalBookingAmount(
      retailPrice,
      extras,
      baggageBoarding,
      transfers
    ) {
      let extrasAmount;
      let baggageBoardingAmount;
      let transfersAmount;

      if (extras?.length) {
        // const insurances = extras.filter(e => e.type === "Insurance");
        extrasAmount = extras.reduce((curr, e) => {
          let current = curr;
          current += Number(e.amount);
          return current;
        }, 0);
      }

      if (baggageBoarding?.length) {
        baggageBoardingAmount = baggageBoarding.reduce((curr, e) => {
          let current = curr;
          current += Number(e.price);
          return current;
        }, 0);
      }

      if (transfers?.length) {
        transfersAmount = transfers.reduce((curr, e) => {
          let current = curr;
          current += Number(e.amount);
          return current;
        }, 0);
      }

      let totalBookingAmount = Number(retailPrice);
      if (extrasAmount) {
        totalBookingAmount += Number(extrasAmount);
      }
      if (baggageBoardingAmount) {
        totalBookingAmount += Number(baggageBoardingAmount);
      }
      if (transfersAmount) {
        totalBookingAmount += Number(transfersAmount);
      }
      return totalBookingAmount;
    },

    calculateMarkup(markup, providerCost) {
      const markupAmount = (Number(providerCost) * Number(markup)) / 100;
      return markupAmount.toFixed(2);
    },

    checkExtrasInitials(data) {
      const extrasInitials = [];
      // console.log("data in extras initial", data);
      if (data.baggageBoarding) {
        const baggage = data.baggageBoarding.find(e => e.comment === "20kg");
        if (baggage) {
          extrasInitials.push("B");
        }
        const priorityBoarding = data.baggageBoarding.find(
          e => e.comment === "Priority"
        );
        if (priorityBoarding) {
          extrasInitials.push("P");
        }
      }
      if (data.extras) {
        const insurance = data.extras.find(e => e.type === "Insurance");
        if (insurance) {
          extrasInitials.push("I");
        }
      }
      if (data.transfers) {
        extrasInitials.push("T");
      }
      return extrasInitials;
    },

    calculateFlightMarkup(flightModuleInfo) {
      const flightMarkup = flightModuleInfo
        ? (
          Number(flightModuleInfo.moduleInfo.flight_price)
              - Number(flightModuleInfo.moduleInfo.base_fare)
        ).toFixed(2)
        : "0";
      return flightMarkup;
    },

    calculateProviderCost(moduleInfo1, flightModuleInfo) {
      const providerCost = flightModuleInfo
        ? Number(moduleInfo1?.providerCost)
            + Number(flightModuleInfo.moduleInfo.base_fare)
        : Number(moduleInfo1?.providerCost);
      return providerCost.toFixed(2);
    },

    transferInitialVariant(data) {
      if (data.transfers) {
        return data.transfers.find(e => e.status === "Cancelled")
          ? "light-secondary"
          : data.transfers.find(e => e.status === "Pending")
            ? "light-warning"
            : "light-success";
      }
      return "";
    },
    openCommentModal(id, comment) {
      this.commentDetails = {
        id,
        comment
      }
      this.$refs.CommentAddEditModal.show();
    },
    openStatusModal(status, id) {
      this.isLoaded = true;
      switch (status) {
        case "Pending":
          status = 0;
          break;
        case "Ongoing":
          status = 1;
          break;
        case "Completed":
          status = 2;
          break;
      }
      this.statusDetails = {
        status,
        id,
      };

      this.$refs.BookingRequestFormModal.show();
    },
    async addCommentvalue() {
      const {
        id,
        comment
      } = this.commentDetails;
      if (comment != "") {
        await bookingRequestService.addEditComment(id, {
          comment
        })
          .then(res => {
            if (resConditionCheck(res.status) && res.data.message) {
              this.toggleModal = false
              this.getBookingListing();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.modal.loading = false;
            this.$refs.modal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      } else {
        await bookingRequestService.deleteComment(id)
          .then(res => {
            if (resConditionCheck(res.status) && res.data.message) {
              this.toggleModal = false
              this.getBookingListing();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.modal.loading = false;
            this.$refs.modal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    },
    async changeBookingRequestStatus() {
      const {
        id, status
      } = this.statusDetails;
      let statusType = "";
      if (status == "0") {
        statusType = "Pending"
      } else if (status == "1") {
        statusType = "Ongoing"
      } else {
        statusType = "Completed"
      }
      try {
        const res = await bookingRequestService.changeBookingStatus(
          {
            booking_quote_id: id,
            statusType,
          },
          this.wlCode
        );
        if (resConditionCheck(res.status) && res.data.message) {
          this.getBookingListing();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    async saveRefund(id, refund, wl_code) {
      // try {
      //   const res = await bookingRequestService.addRefund(
      //     {
      //       refund_amount: refund,
      //       booking_quote_id: id,
      //     },
      //     wl_code
      //   );
      //   if (resConditionCheck(res.status) && res.data.data) {
      //     this.$toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: res.data.message,
      //         icon: "CheckIcon",
      //         variant: "success",
      //       },
      //     });
      //   }
      // } catch (error) {
      //   const errorData = errorResponseHandler(error);
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: errorData,
      //       icon: "X-CircleIcon",
      //       variant: "danger",
      //     },
      //   });
      // }
    },

    // openRefundModal(index) {
    //   this.index = index
    //   this.$refs.refundModal.show()
    // },

    // test(bvModalEvt, refundForm) {
    // },

    navigateToBookingDetail(id, mode, wlcode) {
      if (mode === 2) {
        this.$router.push({
          name: "new-booking-details",
          params: { id },
        });
      } else {
        this.$router.push({
          name: "booking-details",
          params: { id },
          query: {
            page: this.currentPage,
            rows: this.perPage,
            wl_code: wlcode,
          },
        });
      }
    },

    createNewBooking() {
      this.$router.push(`/booking/manual-booking/`);
    },

    disabledRange(date) {
      return date > new Date();
    },

    // bookingIdValidate(e) {
    //   if (!/[A-Za-z0-9_-]+/) {

    //   }
    // }
    getExportData() {
      const WLCODE = sessionStorage.getItem("signinWLAdmin")
          && sessionStorage.getItem("whitelabel_code")
        ? sessionStorage.getItem("whitelabel_code")
        : this.wl_code !== 1
          ? this.wl_code
          : this.wlCode;
      this.exportContentLoading = true;
      const payload = {
        no_of_result: 0,
        page: 1,
        order: this.isSortDirDesc === true ? "DESC" : "ASC",
        order_by: this.sortBy,
        status: this.status,
        payment_via: this.payment_via,
        customer_type: this.customer_type,
        module_id: this.module_id,
        booking_id: this.booking_id,
        email: this.email,
        booking_start_date: this.booking_start_date
          ? this.booking_start_date
          : "",
        booking_end_date: this.booking_end_date ? this.booking_end_date : "",
        travel_start_date: this.travel_start_date,
        travel_end_date: this.travel_end_date,
        booking_mode: this.booking_mode,
        all_whitelabel: this.wlCode === "all",
      };
      bookingRequestService
        .getBookingRequestListing(payload, WLCODE)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.exportResults = res.data.data.map(e => {
              this.exportContentLoading = false;
              if (
                  e.bookings[0]?.moduleId === 1
                  || e.bookings[0]?.moduleId === 3
              ) {
                e.moduleInfo1 = e.bookings.find(
                  z => z.moduleInfo.type === "Hotel"
                );
                e.flightModuleInfo = e.bookings.find(
                  z => z.moduleInfo.type === "Flight"
                );
                e.pendingAmount = e.moduleInfo1?.bookingInstallments
                  ? this.getPendingAmount(e.moduleInfo1.bookingInstallments)
                  : "0";
                e.retailPrice = e.moduleInfo1
                  ? this.calculateRetailPrice(
                    e.moduleInfo1,
                    e.extras,
                    e.flightModuleInfo
                  )
                  : "0";
                e.totalBookingAmount = e.retailPrice
                  ? this.calculateTotalBookingAmount(
                    e.retailPrice,
                    e.extras,
                    e.baggageBoarding,
                    e.transfers
                  )
                  : "0";
                e.currencySymbol = e.currency_details?.symbol
                  ? e.currency_details?.symbol
                  : "";
                e.markupAmount = e.moduleInfo1?.moduleInfo && e.moduleInfo1?.providerCost
                  ? this.calculateMarkup(
                          e.moduleInfo1?.moduleInfo?.rooms?.[0]?.markup,
                          e.moduleInfo1?.providerCost
                  )
                  : "0";
                e.providerCost = e.moduleInfo1
                  ? this.calculateProviderCost(
                    e.moduleInfo1,
                    e.flightModuleInfo
                  )
                  : "0";
                e.flightMarkup = e.flightModuleInfo
                  ? this.calculateFlightMarkup(e.flightModuleInfo)
                  : "0";
              } else if (e.bookings[0]?.moduleId === 2) {
                e.moduleInfo1 = e.bookings.find(
                  z => z.moduleInfo.type === "Flight"
                );
                e.flightModuleInfo = e.moduleInfo1;
                e.pendingAmount = e.moduleInfo1?.bookingInstallments
                  ? this.getPendingAmount(e.moduleInfo1.bookingInstallments)
                  : "0";
                e.retailPrice = e.moduleInfo1
                  ? this.calculateRetailPrice(e.moduleInfo1, e.extras, null)
                  : "0";
                e.totalBookingAmount = e.retailPrice
                  ? this.calculateTotalBookingAmount(
                    e.retailPrice,
                    e.extras,
                    e.baggageBoarding,
                    e.transfers
                  )
                  : "0";
                e.currencySymbol = e.currency_details?.symbol
                  ? e.currency_details?.symbol
                  : "";
                e.markupAmount = "0";
                e.providerCost = e.moduleInfo1
                  ? this.calculateProviderCost(e.moduleInfo1, null)
                  : "0";
                e.flightMarkup = e.moduleInfo1
                  ? this.calculateFlightMarkup(e.moduleInfo1)
                  : "0";
              }
              let adult = 0;
              let child = 0;
              let infant = 0;
                e?.bookingTravellers.forEach(cItem => {
                  adult = cItem.traveller.passanger_code === "ADT" ? adult + 1 : adult;
                  child = cItem.traveller.passanger_code === "CHD" ? child + 1 : child;
                  infant = cItem.traveller.passanger_code === "INF"
                    ? infant + 1
                    : infant;
                });
                return {
                  Booking_Id:
                    e.moduleInfo1 && e.moduleInfo1?.localBookingId
                      ? e.moduleInfo1?.localBookingId
                      : "-",
                  Supplier_Id:
                    e.moduleInfo1 && e.moduleInfo1?.supplierBookingId
                      ? e.moduleInfo1?.supplierBookingId
                      : "-",
                  Creation_Date:
                    e.moduleInfo1 && e.moduleInfo1?.bookingDateTime
                      ? this.moment(e.moduleInfo1?.bookingDateTime).format(
                        "DD-MM-YYYY"
                      )
                      : "-",
                  Status: e.bookingStatus ? e.bookingStatus : "-",
                  Type:
                    e.moduleInfo1 && e.moduleInfo1?.moduleId
                      ? this.TripType(e.moduleInfo1?.moduleId)
                      : "-",
                  Remark:
                    e.remarks && e.remarks?.remarks ? e.remarks?.remarks : "-",
                  Contact_Person: e.user_details
                    ? `${e.user_details.firstName} ${e.user_details.lastName}`
                    : "-",
                  Email: e.user_details ? e.user_details.email : "-",
                  White_Label:
                    e.wlCode && this.wl_code == 1
                      ? this.getWhiteLabelName(e.wlCode)
                      : "-",
                  Agency: e.moduleInfo1?.agency
                    ? this.AgencyStatus(e.moduleInfo1?.agency)
                    : "-",
                  Checkin_Checkout:
                    e.moduleInfo1 && e.moduleInfo1.searchCriteria
                      ? `${this.moment(
                        e.moduleInfo1.searchCriteria.checkin_date
                      ).format("DD-MM-YYYY")} - ${this.moment(
                        e.moduleInfo1.searchCriteria.checkout_date
                      ).format("DD-MM-YYYY")}`
                      : "-",
                  Origin:
                    e.flightModuleInfo && e.flightModuleInfo?.searchCriteria
                      ? e.flightModuleInfo.searchCriteria.origin?.value
                      : "-",
                  Destination:
                    e.moduleInfo1
                    && e.moduleInfo1.moduleId !== 2
                    && e.moduleInfo1?.searchCriteria
                      ? e.moduleInfo1.searchCriteria.value
                      : e.moduleInfo1.searchCriteria.destination.value,
                  Travellers: e.bookingTravellers
                    ? e.bookingTravellers.length
                    : "0",
                  Adult: adult || "0",
                  Child: child || "0",
                  Infant: infant || "0",
                  Currency: e.currency_details ? e.currency_details.code : "-",
                  Payment_Type:
                    e.moduleInfo1 && e.moduleInfo1?.paymentVia
                      ? this.PaymentType(e.moduleInfo1?.paymentVia)
                      : "-",
                  Retail_Price: e.retailPrice
                    ? `${e.retailPrice} ${e.currencySymbol}`
                    : "-",
                  Provider_Cost: e.providerCost
                    ? `${e.providerCost} ${e.currencySymbol}`
                    : "-",
                  Hotel_Markup: e.markupAmount
                    ? `${e.markupAmount} ${e.currencySymbol}`
                    : "-",
                  Flight_Markup: e.flightMarkup
                    ? `${e.flightMarkup} ${e.currencySymbol}`
                    : "-",
                  Discount:
                    e.moduleInfo1 && e.moduleInfo1.discount
                      ? `${Math.abs(e.moduleInfo1.discount)} ${e.currencySymbol}`
                      : "0",
                  Total: e.totalBookingAmount
                    ? Number(e.totalBookingAmount)
                    : "0",
                  First_Installment:
                    e.moduleInfo1
                    && e.moduleInfo1.bookingInstallments
                    && e.moduleInfo1.bookingInstallments[0]
                      ? `${e.moduleInfo1.bookingInstallments[0].amount} ${e.currencySymbol}`
                      : "-",
                  Second_Installment:
                    e.moduleInfo1
                    && e.moduleInfo1.bookingInstallments
                    && e.moduleInfo1.bookingInstallments[1]
                      ? `${e.moduleInfo1.bookingInstallments[1].amount} ${e.currencySymbol}`
                      : "-",
                  Second_Installment_Deadline:
                    e.moduleInfo1
                    && e.moduleInfo1.bookingInstallments
                    && e.moduleInfo1.bookingInstallments[1]
                      ? `${this.moment(
                        e.moduleInfo1.bookingInstallments[1].installmentDate
                      ).format("DD-MM-YYYY")}`
                      : "-",

                  Second_Installment_Payment_Date:
                    e.moduleInfo1
                    && e.moduleInfo1.bookingInstallments
                    && e.moduleInfo1.bookingInstallments[1]
                      ? `${this.moment(
                        e.moduleInfo1.bookingInstallments[1].paymentDate
                      ).format("DD-MM-YYYY")}`
                      : "-",
                  Pending: e.pendingAmount
                    ? `${e.pendingAmount} ${e.currencySymbol}`
                    : "0",
                  Refund: e.refund ? e.refund : "0",
                  Billing_Name:
                    e.billingInfo && e.billingInfo.billing_name
                      ? e.billingInfo.billing_name
                      : "-",
                  Postal_Code:
                    e.billingInfo && e.billingInfo.postal_code
                      ? e.billingInfo.postal_code
                      : "-",
                  Country:
                    e.billingInfo && e.billingInfo.country
                      ? e.billingInfo.country
                      : "-",
                  City:
                    e.billingInfo && e.billingInfo.city
                      ? e.billingInfo.city
                      : "-",
                  Address:
                    e.billingInfo && e.billingInfo.address1
                      ? `${e.billingInfo.address1} ${
                        e.billingInfo.address2 ? e.billingInfo.address2 : ""
                      }`
                      : "",
                  VAT:
                    e.billingInfo && e.billingInfo.vat_number
                      ? e.billingInfo.vat_number
                      : "-",
                  Remarks: e.remarks && e.remarks?.notes ? e.remarks.notes : "-",
                };
            });
          }
        })
        .catch(error => {
          this.exportContentLoading = false;
          console.log(error);
          this.exportResults = this.items;
        });
    },

    async getWhitelabelList() {
      this.isLoaded = false;
      const payload = {
        no_of_result: 0,
        page: 1,
        order: "ASC",
        order_by: "name",
      };
      await whitelabelService
        .getWhitelableList(payload)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.isLoaded = true;
            this.whitelabelList = res.data.data.map(item =>
            // if(!item.isDeleted) {
              ({
                label: item.name,
                value: item.id,
              })
              // }
            );
            this.whitelabelList.push({ label: "Beontrips", value: 1 });
            this.whitelabelList.unshift({ label: "All", value: "all" });
          }
        })
        .catch(error => {
          console.log("Error", error);
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        });
    },
    getWhiteLabelName(wlCode) {
      const label = this.whitelabelList.find(wl => wl.value === wlCode)?.label;
      return label || "Beontrips";
    },
    showExportModal() {
      setTimeout(() => {
        this.progressExport = 25;
      }, 200);
      this.getExportData();
      setTimeout(() => {
        this.progressExport = 50;
      }, 500);
      setTimeout(() => {
        this.progressExport = 75;
      }, 700);
      setTimeout(() => {
        this.progressExport = 100;
      }, 1000);
      this.$refs["export-modal"].show();
    },
    hideExportModal() {
      this.progressExport = 0;
      setTimeout(() => {
        this.progressExport = 10;
      }, 500);
      this.$refs["export-modal"].hide();
    },

    async getWhitelabelDetails() {
      const wlCode = axios.defaults.headers.wl_code;
      await whitelabelService
        .getWhitelabelDetail(wlCode)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.whitelabelDetail = res.data.data;
            this.whitelabelDetail.settingsData.modules.map(e => {
              if (e.isActive === false) {
                const find = this.tripTypeFilter2.findIndex(i => e.name === i.label);
                find > -1 ? this.tripTypeFilter2.splice(find, 1) : "";
              }
            });
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        });
    },

    async applyFilter() {
      this.currentPage = 1;
      const searchData = JSON.parse(sessionStorage.getItem("searchData"));
      if (searchData) {
        searchData.page = 1; // updating the status property
        sessionStorage.setItem("searchData", JSON.stringify(searchData)); // updating resetFilterthe searchData in sessionStorage
      }
      await this.getBookingListing();
    },

    async resetFilter() {
      this.showStatus = "";
      this.status = "";
      this.search = "";
      this.search_id = "";
      this.email = "";
      this.booking_start_date = "";
      this.booking_end_date = "";
      this.wlCode = "all";
      sessionStorage.removeItem("searchData");
      await this.getBookingListing();
    },
  },
};
</script>

<style>
.ql-editor {
  min-height: 100px;
  max-height: 350px;
}
</style>
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  /** No booking fixed label css
      position: absolute;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
   */
   input[type="file"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  </style>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";

  </style>
  <style>
.ql-editor {
  min-height: 100px;
  max-height: 350px;
}
</style>
  <style lang="scss" scoped>
  .custom_input_area {
      background: #efefef;
      padding: 0.438rem 1rem;
      margin-top: 5px;
      border-radius: 0.357rem;
      border: 1px solid #d8d6de;
  }
  .repeater-form {
    overflow: hidden;
    transition: .35s height;
  }
  .emailRemove{
    cursor: pointer;
    border-radius: 50%;
    transition: .25s ease-in-out;
  }
  .emailRemove:hover {
    background: rgb(187, 14, 14);
    color: #fff;
    box-shadow: 0px 0px 10px 2px #efefef;
  }
  </style>
  <style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card {
  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.dark-layout {
  .b-overlay-wrap ::v-deep .b-overlay {
    // border: 10px solid red;
    .bg-white {
      background-color: $theme-dark-body-bg !important;
    }
  }
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
</style>
