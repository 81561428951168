<template>
    <div>
      <b-modal
        id="modal-prevent-closing"
        ref="formModal"
        v-model="toggleModal"
        title="Change Booking Status"
        ok-title="Submit"
        no-close-on-backdrop
        no-close-on-esc
        cancel-variant="outline-secondary"
        centered
        @ok="handleOk"
      >
        <!-- <validation-observer ref="simpleRules"> -->
        <b-form @submit.prevent>
          <b-form-group label="Select Status" label-for="mc-select-status">
            <!-- <validation-provider
              #default="{ errors }"
              name="Gender"
            > -->
            <v-select
              v-model="statusDetails.status"
              class="status"
              placeholder="Select Status"
              :options="BookingRequestStatusEnum"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="val => val.value"
              :clearable="false"
            />
            <!-- <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider> -->
          </b-form-group>
  
          <!-- <b-form-group label="Supplier ID" label-for="name-input"> -->
            <!-- <validation-provider
                #default="{ errors }"
                name="supplier id"
                rules="required"
              > -->
  
            <!-- <b-form-input
              id="name-input"
              v-model="statusDetails.supplierId"
              :readonly="statusDetails.flag === true ? true : false"
            /> -->
            <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
          <!-- </b-form-group> -->
  
          <b-form-group label="Booking ID" label-for="name-input">
            <!-- <validation-provider
                #default="{ errors }"
                name="booking id"
                rules="required"
              > -->
  
            <b-form-input v-model="statusDetails.id" readonly />
            <!-- <small class="text-danger">{{ errors[0] }}</small> -->
            <!-- </validation-provider> -->
          </b-form-group>
  
          <!-- <validation-provider
              #default="{ errors }"
              name="Cancellation Reason"
              rules="required"
            > -->
          <div v-if="statusDetails.status === 3">
            <label for="textarea-default">Cancellation Reason</label>
            <b-form-textarea
              id="textarea-default"
              v-model="statusDetails.cancellationReason"
              placeholder="Textarea"
              rows="3"
            />
            <!-- <small class="text-danger">{{ errors[0] }}</small> -->
          </div>
          <!-- </validation-provider> -->
        </b-form>
        <!-- </validation-observer> -->
      </b-modal>
    </div>
  </template>
  
  <script>
  import {
      BFormGroup,
      BFormInput,
      BModal,
      VBModal,
      BForm,
      BFormTextarea
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  // import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from "@/@core/utils/validations/validations";
  import vSelect from "vue-select";
  import { BookingRequestStatusEnum } from "@/helpers/constant";
  
  export default {
      components: {
          BFormGroup,
          BFormInput,
          BModal,
          BForm,
          vSelect,
          BFormTextarea
      },
      directives: {
          "b-modal": VBModal,
          Ripple
      },
  
      props: {
          statusDetails: {
              type: Object,
              required: true
          },
          isLoaded: {
              type: Boolean,
              required: true
          }
      },
  
      data() {
          return {
              toggleModal: false,
              BookingRequestStatusEnum,
              required
          };
      },
  
      // computed: {
      //     setData() {
  
      //     }
      // },
      methods: {
          show() {
              this.toggleModal = true;
          },
          handleOk(bvModalEvt) {
              this.$emit("on-submit", bvModalEvt, this.refundForm);
          }
      }
  };
  </script>
  