<template>
  <div>
    <b-modal
      id="modal-send-email"
      ref="CommentAddEditModal"
      v-model="toggleModal"
      title="Comment"
      cancel-title="Close"
      ok-title="comment"
      size="md"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="handleOk"
    >
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >
        <validation-observer ref="emailFormRules">
          <b-form
            ref="emailForm"
            :style="{height: trHeight}"
            class="repeater-form"
            @submit.prevent
          >
            <b-row>
              <b-col>
                <h4 for="emails">
                  Comment:
                </h4>
              </b-col>
              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="Message"
                  rules="max:250|min:3"
                >
                  <b-form-group>
                    <b-form-textarea
                      id="message"
                      v-model="commentDetails.comment"
                      class="message"
                      placeholder="Message"
                      :preserve-whitespace="false"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormGroup,
  BModal,
  VBModal,
  BForm,
  BFormTextarea,
  BOverlay,
  BCol,
  BRow,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@/@core/utils/validations/validations";
import { BookingRequestStatusEnum } from "@/helpers/constant";
import { heightTransition } from '@core/mixins/ui/transition';

export default {
  components: {
    BFormGroup,
    BModal,
    BForm,
    BFormTextarea,
    BOverlay,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BRow,
  },
  directives: {
    "b-modal": VBModal,
    Ripple
  },
  mixins: [heightTransition],

  props: {
    commentDetails: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      sendCommentForm: {
        message: '',
      },
      toggleModal: false,
      BookingRequestStatusEnum,
      required,
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline"]
          ]
        }
      }
    };
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },

  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    show() {
      this.toggleModal = true;
    },
    handleOk(bvModalEvt) {
      this.$emit("on-submit", bvModalEvt, this.refundForm);
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.emailForm?.scrollHeight + 52)
      })
    },
  }
};
</script>